import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import {
   BankAccountsCreateResolve,
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GroupsSearchResolve,
   PaymentLinksSearchResolve,
} from '~/api';
import { states, bankCodes, accountType } from '~/services/data';
import { CPFValidation } from '~/services';
import history from '~/config/history';
import { useUser } from '~/hooks';
import {useRecoilState} from "recoil";
import {sellerPageSelectState} from "~/recoil/seller-select.state";

const yupValidateSchema = Yup.object().shape({
   holder_name: Yup.string().required('Esse campo é obrigatório!'),
   person_type: Yup.string().required('Esse campo é obrigatório!'),
   // customer_id: Yup.string().nullable().required('Esse campo é obrigatório!'),
   taxpayer_id: Yup.string()
      .required('Esse campo é obrigatório!')
      .when('person_type', {
         is: '2',
         then: Yup.string().test('cpf-length', 'Digite o CNPJ corretamente', (val): any => {
            const newVal = val?.replace(/\D/g, '');

            return newVal?.length === 14;
         }),
      })
      .when('person_type', {
         is: '1',
         then: Yup.string().test('cpf-valid', 'Digite um CPF válido', CPFValidation),
      }),
   type: Yup.string().required('Esse campo é obrigatório!'),
   bank_code: Yup.string().required('Esse campo é obrigatório!'),
   routing_number: Yup.string().required('Esse campo é obrigatório!'),
   account_number: Yup.string().required('Esse campo é obrigatório!'),
});

export function useBanksAccountsCreatePage(): any {
   document.title = 'Nova conta bancária - XPAG Tecnologia';

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      yupValidateSchema,
      resolves: {
         // groups: GroupsSearchResolve,
      },
      resolveCreate: BankAccountsCreateResolve,
      handleFormData: (formData: any) => {
         const newForm = { ...formData };
         console.log(newForm);

         return newForm;
      },
      onFailed() {
         ToastList.show({
            message: 'Erro ao adicionar conta',
            type: 'error',
         });
      },
      onSuccess(creating) {
         ToastList.show({
            message: 'Conta adicionada com sucesso',
            type: 'success',
         });
         history.push('/bank-accounts');
      },
   });

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerPageSelectState);
   const user = useUser();
   const seller = sellerSelect ?? user.sellers[0];

   const [groups, setGroups] = useState<any[]>([]);
   const [personType, setPersonType] = useState(seller?.type == 'business' ? 2 : 1);

   const initialData = {
      person_type: seller?.type == 'business' ? 2 : 1,
      holder_name: seller?.name,
      taxpayer_id: seller?.document,
      customer_id: seller?.id,
   };

   useEffect(() => {
      formRef.current.setData(initialData);
   }, [sellerSelect])

   registerOnInit((resources: any) => {
      if (resources.groups) {
         setGroups(resources.groups.results);
      }

      if (resources.resource) {
         const resource = { ...resources.resource };
         if (resource.group_id) {
            resource.group_id = { label: resource.group_id, value: resource.group_name };
         }

         setPersonType(resource.person_type);
         formRef.current.setData(resource);
      }
   });

   const formInfoData = {
      groups: [
         {
            id: '',
            name: 'Sem grupo',
         },
         ...groups.map((group) => ({
            id: group.id,
            name: group.name,
         })),
      ],
      person_type: [
         {
            id: 1,
            name: 'Pessoa física',
         },
         {
            id: 2,
            name: 'Pessoa júrica',
         },
      ],
      states,
      bankCodes: bankCodes.map((i) => ({ id: i.id, name: `${i.id} - ${i.name}` })),
      accountType,
   };

   function customersResolve(customer_name: string): any {
      return CustomersSearchResolve({ limit: 5, customer_name }).pipe(map((response) => response.results));
   }

   function openCreateGroup(): void {
      history.push('/groups/create');
   }

   function onChangePersonType(): void {
      setPersonType(formRef.current.getFieldValue('person_type'));
   }

   return {
      onSubmit: onSubmitForm,
      customersResolve,
      user,
      initialData,
      openCreateGroup,
      onChangePersonType,
      personType,
      formRef,
      reloadPage,
      starting,
      saving,
      errorLoadData,
      formInfoData,
   };
}
