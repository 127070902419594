import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Input, Select, SelectAutocomplete } from '@devesharp/react/web';
import { Form } from '@unform/web';
import Style, { ProgressColor } from './ChangePasswordPage.style';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import { useGroupsCreatePage } from '~/pages/GroupsCreate/GroupsCreatePage.controller';
import { useMyAccountPage } from '~/pages/MyAccount/MyAccountPage.controller';
import { useChangePasswordPage } from '~/pages/ChangePassword/ChangePasswordPage.controller';

export const ChangePasswordPage: React.FunctionComponent<any> = () => {
   const ctrl = useChangePasswordPage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">Mudar senha</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <div className="form-title">Preencha os campos</div>

                     <br />

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="old_password" title="Senha atual" type="password" />
                        </div>
                     </div>

                     <div className="row field-box">
                        <div className="col-md-6">
                           <Input name="new_password" title="Nova senha" type="password" />
                        </div>
                        <div className="col-md-6">
                           <Input name="re_new_password" title="Repita a nova senha" type="password" />
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>Atualizar dados</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};
