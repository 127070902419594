import React, { FormEvent, useEffect, useLayoutEffect, useState } from 'react';
import { Container, Table, Card, Fade } from 'react-bootstrap';
import { Button, Checkbox, convertStringToDate, Input } from '@devesharp/react/web';
import { Form } from '@unform/web';
import Skeleton from 'react-loading-skeleton';
import { PhoneMask } from '@devesharp/react/dist/components1/MaskText/mask.utils';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import DateFNS from 'date-fns';
import { CNPJAndCPFMask, formatDate } from '~/services';
import history from '~/config/history';
import { useSplitCreatePage } from './SplitCreatePage.controller';
import { PaymentStatus, Header, LoadingPage, LinkPaymentStatus } from '~/components';
import Style, { ProgressColor } from './SplitCreatePage.style';
import { SellerSelectInput } from '~/components/SellerSelectInput';
import { Select } from '~/components/Customs/Select';
import { Switch } from '~/components/Customs/Switch';

export const SplitCreatePage: React.FunctionComponent<any> = () => {
   const ctrl = useSplitCreatePage();

   return (
      <Style>
         <div className="title-page">
            <div className="container">
               <div className="p-3">
                  <div className="row">
                     <div className="col">
                        <h3 className="m-0 mb-1">{ctrl.editing ? `Editar split #${ctrl.id}` : 'Novo split'}</h3>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         {(ctrl.saving || ctrl.starting) && <LoadingPage />}

         <div className="container-form m-auto">
            <Form ref={ctrl.formRef} onSubmit={ctrl.onSubmit} initialData={{ active: true }}>
               <Card className="p-n">
                  <div className="form-padding ">
                     <>
                        <div className="row field-box">
                           <div className="col-md-12">
                              <Switch name="active" title="Split Ativo" />
                           </div>
                        </div>
                     </>

                     <div className="row field-box pt-3">
                        <div className="col-md-12">
                           <Input name="name" title="Nome do Split" />
                        </div>
                     </div>

                     <div className="row field-box pt-3">
                        <div className="col-md-6">
                           <Select name="seller_id_origin" title="Vendedor" options={ctrl.formData.sellers} />
                        </div>

                        <div className="col-md-6">
                           <Select name="seller_hash_receiver" title="Recebedor" options={ctrl.formData.sellers} />
                        </div>
                     </div>

                     <div className="row field-box pt-3">
                        <div className="col-md-12">
                           <Select
                              name="fee_for_receiver"
                              title="Quem deve pagar a taxa?"
                              options={ctrl.formData.fee_for_receiver}
                           />
                        </div>
                     </div>
                     <div className="row field-box pt-3">
                        <div className="col-md-12">
                           <Select
                              name="liable"
                              title="Quem arca com prejuízo em caso de chargeback?"
                              options={ctrl.formData.liable}
                           />
                        </div>
                     </div>

                     <div className="row field-box pt-3">
                        <div className="col-md-6">
                           <Select name="type" title="Tipo da comissão" options={ctrl.formData.type} />
                        </div>

                        <div className="col-md-6">
                           <Input
                              name="value"
                              title="Valor da comissão"
                              number
                              placeholder="0,00"
                              decimals={2}
                              prefix="R$ "
                              textRight
                           />
                           <div>O valor da comissão será calculado em cima do valor total da transação.</div>
                        </div>
                     </div>
                  </div>
               </Card>
               <div className="text-right pt-2">
                  <Button size="lg" type="submit">
                     <b>{ctrl.editing ? 'Editar split' : 'Criar split'}</b>
                  </Button>
               </div>
               <br />
               <br />
            </Form>
         </div>
      </Style>
   );
};

const ItemListing = React.memo(function ItemListing({ customer, skeleton, selected, removeGroup, sendSMS }: any): any {
   const [show, setShow] = useState(false);

   useEffect(() => {
      setShow(true);
   }, []);

   if (skeleton) {
      return (
         <tr>
            <td />
            <td>
               <Skeleton height={16} width={16} />
            </td>
            <td>
               <Skeleton height={18} width={100} />
               <br />
               <Skeleton height={12} width={50} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td>
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-center">
               <Skeleton height={23} width={50} style={{ borderRadius: '100px' }} />
            </td>
            <td className="text-right">
               <Skeleton height={20} width={80} />
            </td>
            <td className="text-right" />
         </tr>
      );
   }

   function openTransaction(): void {
      history.push(`/link-payments/detail/${customer.id}`);
   }

   return (
      <Fade in={show} timeout={500}>
         <tr className={`${selected && 'selected'} cursor-pointer`}>
            <td>{customer.full_name}</td>
            <td>{CNPJAndCPFMask(customer.taxpayer_id)}</td>
            <td className="text-right">{PhoneMask(customer.phone_number)}</td>
            <td className="text-center text-nowrap">
               <b>{formatDate(customer.created_at)}</b>
            </td>
            <td className="text-right text-nowrap">
               <Button onClick={() => removeGroup(customer.id)} variant="danger" size="sm">
                  Remover do grupo
               </Button>
            </td>
         </tr>
      </Fade>
   );
}, _.isEqual);
