import React, { useEffect, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import {
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   GroupsCreateResolve,
   GroupsGetResolve,
   GroupsSearchResolve,
   GroupsUpdateResolve,
   PaymentCancelVoidResolve,
   PaymentLinksSearchResolve,
   PaymentsGetResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation, OnlyNumbers } from '~/services';
import history from '~/config/history';

export function useGroupsCreatePage(): any {
   const { id } = useParams<any>();
   const [resource, setResource] = useState<any>();

   document.title = 'Crir grupo - XPAG Tecnologia';

   const yupValidateSchema = Yup.object().shape({
      amount: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('empty', 'Esse campo é obrigatório!', (val: any) => {
            return Number(OnlyNumbers(val)) > 0;
         })
         .test('bigger', 'Valor a cancelar não pode ser maior que valor da venda', (val: any) => {
            return Number(OnlyNumbers(val)) <= Number(resource?.amount * 100);
         }),
   });

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      id,
      yupValidateSchema,
      resolveGet: PaymentsGetResolve,
      resolveUpdate: (params) => PaymentCancelVoidResolve(id, params),
      onFailed(create, resp: any) {
         ToastList.show({
            message: `Erro ao cancelar venda: ${resp?.data?.error}`,
            type: 'error',
         });
      },
      onSuccess(creating) {
         ToastList.show({
            message: 'Venda cancelada com sucesso!',
            type: 'success',
         });
         history.push(`/payments/detail/${id}`);
      },
   });

   registerOnInit((resources: any) => {
      if (resources.resource) {
         const resource = { ...resources.resource };

         resource.amount = Number(resource.amount) * 100;
         resource.original_amount = Number(resource.original_amount) * 100;

         formRef.current.setData(resource);

         setResource(resource);
      }
   });

   return {
      onSubmit: onSubmitForm,
      id,
      editing: !!id,
      resource,
      formRef,
      reloadPage,
      starting,
      saving,
      errorLoadData,
   };
}
