import React, { useEffect, useRef, useState } from 'react';
import dateFNS from 'date-fns';
import { convertStringToDate, Input, LoaderIcon, SelectAutocomplete, useClickOutside } from '@devesharp/react/web';
import { faBarcode, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from '@unform/web';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Style from './SellerSelect.style';
import { useViewList } from '~/hooks/useViewList';
import { SellersGetResolve, SellersSearchResolve } from '~/api';
import { CNPJAndCPFMask } from '~/services';
import { useDebounce } from '~/hooks/useDebounce';
import { LoadingPage } from '~/components';
import {
   sellerPageSelectState,
   sellerSelectState,
   sellerShowInfoState,
   uniqueSellerUserState,
} from '~/recoil/seller-select.state';
import { useUser } from '~/hooks';

export function SellerSelect(): any {
   const user = useUser();
   const [value, setValue] = useState('');
   const dropRef = useRef(null);
   const [sellerPageSelect, setSellerPageSelect] = useRecoilState(sellerPageSelectState);
   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);
   const [s, setShowInfoSeller] = useRecoilState(sellerShowInfoState);
   const [selectedName, setSelectedName] = useState(sellerPageSelect?.name ?? 'Todos os estabelecimentos');
   const [uniqueSeller, setUniqueSeller] = useRecoilState(uniqueSellerUserState);

   useEffect(() => {
      if (user.sellers?.length == 1 && user.role != 'MASTER') {
         setUniqueSeller(true);
         setShowInfoSeller(false);
         setSelectedName(user.sellers[0].name);
         setSellerSelect({
            current_balance: user.sellers[0].current_balance ?? 0,
            name: user.sellers[0].name,
            id: user.sellers[0].id,
            document: user.sellers[0].document,
            type: user.sellers[0].type,
         });
         setSellerPageSelect({
            current_balance: user.sellers[0].current_balance ?? 0,
            name: user.sellers[0].name,
            id: user.sellers[0].id,
            document: user.sellers[0].document,
            type: user.sellers[0].type,
         });
      } else {
         setUniqueSeller(false);
      }
   }, []);

   useEffect(() => {
      if (!sellerSelect && (user.sellers?.length > 1 || user.role == 'MASTER')) {
         setShowInfoSeller(true);
      }
   }, [sellerSelect]);

   useEffect(() => {
      if (sellerPageSelect) {
         updateBalance(sellerPageSelect.id);
      }
   }, []);

   function updateBalance(id: any): any {
      SellersGetResolve(id).subscribe((e) => {
         setSellerPageSelect((i: any) => ({ ...i, current_balance: e.current_balance }));
      });
   }

   const [visibleDropDown, setVisibleDropDown] = useState(false);

   const { resources, setFilters, searching, starting } = useViewList({
      resolves: {
         items: SellersSearchResolve,
      },
   });

   useClickOutside(() => {
      setVisibleDropDown(false);
   }, dropRef);

   function onSelectSeller(seller: any): void {
      if (!seller) {
         setSellerPageSelect(null);
         setSellerSelect(null);
         setSelectedName('Todos os estabelecimentos');
      } else {
         setSelectedName(seller.name);
         setSellerPageSelect({
            current_balance: seller.current_balance ?? 0,
            name: seller.name,
            type: seller.type,
            id: seller.id,
            document: seller.document,
         });
         setSellerSelect({
            current_balance: seller.current_balance ?? 0,
            name: seller.name,
            type: seller.type,
            id: seller.id,
            document: seller.document,
         });

         updateBalance(seller.id);
      }
      setVisibleDropDown(false);
      setValue('');
   }

   const valueSearch = useDebounce(value, 250);

   useEffect(() => {
      setFilters(() => ({
         name: valueSearch,
      }));
   }, [valueSearch]);

   if (uniqueSeller) {
      return null;
   }

   return (
      <Style ref={dropRef}>
         <div>
            {/* <Form onSubmit={() => {}}> */}
            {/*   <SelectAutocomplete title="" name="seller" placeholder="Selecione o estabelecimento" /> */}

            <div className="d-flex align-items-end" onClick={() => setVisibleDropDown((i) => !i)}>
               <div>
                  <div className="text-title">Você está como</div>
                  <div className="selected">{selectedName}</div>
               </div>
               <div className="arrow">
                  <svg
                     height="20"
                     width="20"
                     viewBox="0 0 20 20"
                     aria-hidden="true"
                     focusable="false"
                     fill="white"
                     className="css-6q0nyr-Svg"
                  >
                     <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
                  </svg>
               </div>
            </div>

            {/* <div className="input-border" onClick={() => setVisibleDropDown((i) => !i)}> */}
            {/*   {!sellerSelect && ( */}
            {/*      <div className="input-container--inner place-holder"> */}
            {/*         <div>Selecione o estabelecimento</div> */}
            {/*      </div> */}
            {/*   )} */}
            {/*   {!!sellerSelect && ( */}
            {/*      <div className="input-container--inner"> */}
            {/*         <div>{sellerSelect.name}</div> */}
            {/*      </div> */}
            {/*   )} */}
            {/*   <div className="suffix" /> */}
            {/*   <span className="line" /> */}
            {/*   <div className="suffix down-icon"> */}
            {/*      <svg */}
            {/*         height="20" */}
            {/*         width="20" */}
            {/*         viewBox="0 0 20 20" */}
            {/*         aria-hidden="true" */}
            {/*         focusable="false" */}
            {/*         fill="white" */}
            {/*         className="css-6q0nyr-Svg" */}
            {/*      > */}
            {/*         <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" /> */}
            {/*      </svg> */}
            {/*   </div> */}
            {/* </div> */}

            {visibleDropDown && (
               <div className="dropdown">
                  <div className="search">
                     <Form onSubmit={() => {}}>
                        <Input
                           name="sdsd"
                           placeholder="Buscar CPF, CNPJ ou Nome"
                           onKeyUp={(e: any) => {
                              setValue(e.target.value);
                           }}
                           suffix={
                              <>
                                 <FontAwesomeIcon icon={faSearch} color="black" />
                              </>
                           }
                        />
                     </Form>
                  </div>

                  <div className="listing">
                     {(searching || starting) && (
                        <div className="loading-page">
                           <LoaderIcon />
                        </div>
                     )}
                     {!starting && (
                        <div className="item" onClick={() => onSelectSeller(null)}>
                           <div className="name">Todos</div>
                           <div className="document">Visualizar todos estabelecimentos</div>
                        </div>
                     )}
                     {!searching &&
                        resources?.map((resource: any) => (
                           <div className="item" onClick={() => onSelectSeller(resource)}>
                              <div className="name">{resource.name}</div>
                              <div className="document">
                                 {resource.document.length > 11 ? 'CNPJ' : 'CPF'} {CNPJAndCPFMask(resource.document)}
                              </div>
                           </div>
                        ))}
                     {!searching && resources.length === 0 && !searching && !starting && (
                        <div className="empty">Nenhum estabelecimento encontrado</div>
                     )}
                  </div>
                  <div className="see-all">
                     <Link to="/sellers/all">Ver todos</Link>
                  </div>
               </div>
            )}
         </div>
         {/* </Form> */}
      </Style>
   );
}
