import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
   Tabs,
   useView,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   useViewForm,
   ToastList,
} from '@devesharp/react/web';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import Datefns from 'date-fns';
import { useImmer } from 'use-immer';
import * as Yup from 'yup';
import { AuthMeUpdateAPI, PaymentLinksPublicGetResolve, PaymentLinksPublicPayResolve } from '~/api';
import { OnlyNumbers } from '~/services';

const yupValidateSchema = Yup.object().shape({
   card: Yup.object().shape({
      card_number: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('card-length', 'Digite o número correto do cartão!', (val: any) => {
            return OnlyNumbers(val).length === 16;
         }),
      holder_name: Yup.string().required('Esse campo é obrigatório!'),
      expiration_month: Yup.string().required('Esse campo é obrigatório!'),
      expiration_year: Yup.string().required('Esse campo é obrigatório!'),
      security_code: Yup.string()
         .required('Esse campo é obrigatório!')
         .test('cvv-length', 'Digite o código de segurança completo.', (val: any) => {
            return OnlyNumbers(val).length === 3;
         }),
   }),
});

export function useLinkPaymentsPayPage(): any {
   const { id } = useParams() as any;
   document.title = 'Link de pagamento - XPAG Tecnologia';

   const [succeeded, setSucceeded] = useState(false);
   const {
      starting,
      errorLoadData,
      saving,
      formRef,
      onSubmitForm,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      resolves: {
         resource: PaymentLinksPublicGetResolve,
      },
      yupValidateSchema,
      handleFormData: (formData: any) => {
         formData.three_d_secure = {
            user_agent: navigator.userAgent,
            device: {
               color_depth: window.screen.colorDepth,
               type: 'BROWSER',
               java_enabled: navigator.javaEnabled(),
               language: 'BR',
               screen_height: window.screen.height,
               screen_width: window.screen.width,
               time_zone_offset: 3,
            },
         };

         return { ...formData, id };
      },
      resolveCreate: PaymentLinksPublicPayResolve,
      onFailed(create, resp: any) {
         alert(`Erro ao realizar pagamento \n \n ${resp?.data?.error}`);
      },
      onSuccess() {
         setSucceeded(true);
      },
   });

   const [resource, setResource] = useState<any>();
   const [creditCard, setCreditCard] = useImmer({
      cvc: '',
      expiry: '',
      focus: '',
      name: '',
      number: '',
   });

   registerResolveParams('resource', () => id);

   registerOnInit((res: any) => {
      setResource(res.resource);
   });

   const formInfoData = useMemo(
      () => ({
         expiration_month: [
            {
               id: '1',
               name: '1',
            },
            {
               id: '2',
               name: '2',
            },
            {
               id: '3',
               name: '3',
            },
            {
               id: '4',
               name: '4',
            },
            {
               id: '5',
               name: '5',
            },
            {
               id: '6',
               name: '6',
            },
            {
               id: '7',
               name: '7',
            },
            {
               id: '8',
               name: '8',
            },
            {
               id: '9',
               name: '9',
            },
            {
               id: '10',
               name: '10',
            },
            {
               id: '11',
               name: '11',
            },
            {
               id: '12',
               name: '12',
            },
         ],
         installments: resource?.installments
            ? [
                 {
                    id: resource?.installments,
                    name: `${resource?.installments}x`,
                 },
              ]
            : [
                 {
                    id: '1',
                    name: '1x',
                 },
                 {
                    id: '2',
                    name: '2x',
                 },
                 {
                    id: '3',
                    name: '3x',
                 },
                 { id: '4', name: '4x' },
                 { id: '5', name: '5x' },
                 { id: '6', name: '6x' },
                 { id: '7', name: '7x' },
                 { id: '8', name: '8x' },
                 { id: '9', name: '9x' },
                 { id: '10', name: '10x' },
                 { id: '11', name: '11x' },
                 { id: '12', name: '12x' },
              ],
         expiration_year: [
            {
               id: Datefns.getYear(new Date()),
               name: Datefns.getYear(new Date()),
            },
            {
               id: Datefns.getYear(new Date()) + 1,
               name: Datefns.getYear(new Date()) + 1,
            },
            {
               id: Datefns.getYear(new Date()) + 2,
               name: Datefns.getYear(new Date()) + 2,
            },
            {
               id: Datefns.getYear(new Date()) + 3,
               name: Datefns.getYear(new Date()) + 3,
            },
            {
               id: Datefns.getYear(new Date()) + 4,
               name: Datefns.getYear(new Date()) + 4,
            },
            {
               id: Datefns.getYear(new Date()) + 5,
               name: Datefns.getYear(new Date()) + 5,
            },
            {
               id: Datefns.getYear(new Date()) + 6,
               name: Datefns.getYear(new Date()) + 6,
            },
            {
               id: Datefns.getYear(new Date()) + 7,
               name: Datefns.getYear(new Date()) + 7,
            },
            {
               id: Datefns.getYear(new Date()) + 8,
               name: Datefns.getYear(new Date()) + 8,
            },
            {
               id: Datefns.getYear(new Date()) + 9,
               name: Datefns.getYear(new Date()) + 9,
            },
            {
               id: Datefns.getYear(new Date()) + 10,
               name: Datefns.getYear(new Date()) + 10,
            },
            {
               id: Datefns.getYear(new Date()) + 11,
               name: Datefns.getYear(new Date()) + 11,
            },
            {
               id: Datefns.getYear(new Date()) + 12,
               name: Datefns.getYear(new Date()) + 12,
            },
            {
               id: Datefns.getYear(new Date()) + 13,
               name: Datefns.getYear(new Date()) + 13,
            },
         ],
      }),
      [resource?.installments],
   );

   return {
      starting,
      saving,
      succeeded,
      errorLoadData,
      resource,
      formRef,
      onSubmitForm,
      formInfoData,
      creditCard,
      setCreditCard,
   };
}
