import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
   Tabs,
   useViewList,
   useDidUpdateEffect,
   useDidUpdateDeepCompareLayoutEffect,
   ToastList,
   useSelectGroup,
   useViewForm,
} from '@devesharp/react/web';
import * as Yup from 'yup';
import dateFNS from 'date-fns';
import { FormHandles } from '@unform/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import {
   CustomersCreateResolve,
   CustomersGetResolve,
   CustomersSearchResolve,
   CustomersUpdateResolve,
   SplitCreateResolve,
   SplitGetResolve,
   SplitSearchResolve,
   SplitUpdateResolve,
   PaymentLinksSearchResolve,
   SellersAllSearchResolve,
} from '~/api';
import { states } from '~/services/data';
import { CPFValidation } from '~/services';
import history from '~/config/history';
import { sellerSelectState } from '~/recoil/seller-select.state';

const yupValidateSchema = Yup.object().shape({
   name: Yup.string().required('Esse campo é obrigatório!'),
   type: Yup.string().required('Esse campo é obrigatório!'),
   seller_id_origin: Yup.string().nullable().required('Esse campo é obrigatório!'),
   seller_hash_receiver: Yup.string().nullable().required('Esse campo é obrigatório!'),
   fee_for_receiver: Yup.string().required('Esse campo é obrigatório!'),
   liable: Yup.string().nullable().required('Esse campo é obrigatório!'),
   value: Yup.number()
      .nullable()
      .required('Esse campo é obrigatório!')
      .when('type', {
         is: 'FIXED',
         then: Yup.number().min(0.01, 'O valor deve ser maior que 0'),
      })
      .when('type', {
         is: 'PERCENTAGE',
         then: Yup.number().min(0.01, 'O valor deve ser maior que 0%').max(100, 'O valor deve ser menor que 100%'),
      }),
});

export function useSplitCreatePage(): any {
   const { id } = useParams<any>();

   const [sellerSelect, setSellerSelect] = useRecoilState(sellerSelectState);

   document.title = 'Crir grupo - XPAG Tecnologia';

   const {
      starting,
      saving,
      formRef,
      errorLoadData,
      onSubmitForm,
      reloadPage,
      registerResolveParams,
      registerOnInit,
   } = useViewForm({
      id,
      yupValidateSchema,
      resolves: {
         sellers: SellersAllSearchResolve,
      },
      resolveGet: SplitGetResolve,
      resolveCreate: SplitCreateResolve,
      resolveUpdate: (params) => SplitUpdateResolve(id, params),
      handleFormData: (formData: any) => {
         const newForm = { ...formData };
         console.log(newForm.value)
         newForm.value = newForm.value/100;

         return newForm;
      },
      onFailed() {
         ToastList.show({
            message: 'Erro ao adicionar split',
            type: 'error',
         });
      },
      onSuccess(creating) {
         if (creating) {
            ToastList.show({
               message: 'Split adicionado com sucesso',
               type: 'success',
            });
            history.push('/split-payments');
         } else {
            ToastList.show({
               message: 'Split editado com sucesso',
               type: 'success',
            });
            history.push('/split-payments');
         }
      },
   });

   const [customers, setCustomers] = useState([]);
   const [sellers, setSellers] = useState([]);
   registerOnInit((resources: any) => {
      if (resources.sellers) {
         setSellers(
            resources.sellers.sort(function (a: any, b: any) {
               if (a.name.trim().toLowerCase() < b.name.trim().toLowerCase()) {
                  return -1;
               }
               if (a.name.trim().toLowerCase() > b.name.trim().toLowerCase()) {
                  return 1;
               }
               return 0;
            }),
         );
      }

      if (resources.resource) {
         const resource2 = { ...resources.resource };
         resource2.value = resource2.value*100;
         formRef.current?.setData(resource2);
      }
   });

   const [loading, setLoading] = useState(false);

   function removeGroup(contactId: string): void {
      setLoading(true);
      CustomersUpdateResolve(contactId, {
         group_id: null,
      }).subscribe(
         () => {
            setLoading(false);
            CustomersSearchResolve({
               group_id: id,
               limit: 100,
            }).subscribe(
               (e) => {
                  setCustomers(e.results);
                  setLoading(false);
               },
               () => {
                  setLoading(false);
               },
            );
         },
         () => {
            setLoading(false);
         },
      );
   }

   const formData = useMemo(
      () => ({
         sellers: [
            {
               value: '',
               name: 'Selecione um estabelecimento',
            },
            ...sellers.map((group: any) => ({
               value: group.id,
               name: group.name,
            })),
         ],
         seller_id_origin: [],
         seller_hash_receiver: [],
         fee_for_receiver: [
            {
               value: 'owner',
               name: 'Vendedor',
            },
            {
               value: 'receiver',
               name: 'Recebedor',
            },
            {
               value: 'split',
               name: 'Dividir',
            },
         ],
         liable: [
            {
               value: 'owner',
               name: 'Vendedor',
            },
            {
               value: 'receiver',
               name: 'Recebedor',
            },
         ],
         type: [
            {
               value: 'FIXED',
               name: 'FIXO',
            },
            {
               value: 'PERCENTAGE',
               name: 'PORCENTAGEM',
            },
         ],
      }),
      [sellers],
   );

   return {
      formData,
      onSubmit: onSubmitForm,
      id,
      customers,
      editing: !!id,
      removeGroup,
      formRef,
      reloadPage,
      starting,
      saving: saving || loading,
      errorLoadData,
      sellerSelect,
   };
}
